export const projects = [
    {
      title: "Pine Beetle",
      subtitle: "DALI Lab",
      description:
        "Website to help the US Forest Service and agroforestry researchers visualize and predict Southern Pine Beetle outbreaks.",
      stack: "React, Mongo, Express Node",
      image: "./pine-beetle.png",
      link: "https://pine-beetle-prediction-dev.netlify.app/",
    },
    {
      title: "Tide Pool",
      subtitle: "DALI Lab",
      description:
        "A computer vision tool to help the US National Park Service automate the process of counting and tracking baranacle populations.",
      stack: "Python, OpenCV, Tensorflow, Huggingface, Meta's Segment Anything Model",
      image: "./barnacles.png",
      link: "https://tidepool-tester.onrender.com/",
    },
    {
      title: "DataVinci",
      subtitle: "DALI Lab",
      description:
        "A social-media platform for data fanatics! This project was made to help a Dartmouth professor gather data metrics on how people respond to differences in data visualizations -- a data visualization experiment.",
      stack: "Python, JavaScript, React",
      image: "./divas.png",
      link: "https://art-science-datavis.onrender.com/",
    },
    {
        title: "Leafy Learn",
        subtitle: "CS98",
        description:
          "A game that makes learning how to code less intimidating. Players will need to code to help save the forest!",
        stack: "Unity, C, Figma",
        image: "./leafy-learn.png",
        link: "https://leafy-learn.itch.io/leafy-learn-23f",
    },
    {
      title: "Travel Planner",
      subtitle: "Code Day",
      description:
        "A tool that helps make sure you have everything you need for your next vacation by taking input of location and travel dates and using weather information to make suggestions.",
      stack: "HTML, CSS, JavaScript, Weather API",
      image: "./trip-planner.png",
      link: "https://trip-packer.vercel.app/",
    },
    {
        title: "Avocado Prices",
        subtitle: "CS89.21",
        description:
          "An exploratory project using avocado price data, macroeconomic data, and scraped news articles to see if US macroeconomic variables and news can predict avocado prices.",
        stack: "Python, Beautiful Soup, Matplotlib, Sklearn",
        image: "./avocado.png",
        link: "https://github.com/gracewanggw/avocado-economics/tree/main",
    },
  ];

export const services = [
    {
        title: "Epidemiology Study Design",
        description: "We collaborate with clients to develop tailored epidemiological studies, ensuring robust methodologies and optimal outcomes.",
    },
    {
      title: "RWD Source Identification, Evaluation, and Acquisition",
      description: "Our experts identify and evaluate the most suitable real-world data sources, ensuring reliable and high-quality data for your projects.",
    },
    {
      title: "SQL, SAS, R, and Python Programming",
      description: "We leverage industry-standard programming languages to efficiently implement and execute studies, delievering accurate and timely results.",
    },
    {
      title: "Data Visualization and Dashboard Creation",
      description: "We transform complex data into clear, visually appealing, and interactive dashboards, enabling data-driven decision-making.",
    },
]

export const frontendSkills = [
  {
    logo: './logo192.png',
    name: 'ReactJS'
  },
  {
    logo: './js-logo.png',
    name: 'JavaScript'
  },
  {
    logo: './html-logo.png',
    name: 'HTML'
  },
  {
    logo: './css-logo.png',
    name: 'CSS'
  },
]

export const backendSkills = [
  {
    logo: './java-logo.png',
    name: 'Java'
  },
  {
    logo: './python-logo.png',
    name: 'Python'
  },
  {
    logo: './spring-logo.png',
    name: 'Spring'
  },
  {
    logo: './mongo-logo.png',
    name: 'MongoDB'
  },
]

export const dataSkills = [
  {
    logo: './python-logo.png',
    name: 'Python'
  },
  {
    logo: './spacy-logo.jpeg',
    name: 'SpaCy'
  },
  {
    logo: './pytorch-logo.png',
    name: 'PyTorch'
  },
  {
    logo: './opencv-logo.png',
    name: 'OpenCV'
  },
  {
    logo: './pandas-logo.png',
    name: 'Pandas'
  },
  {
    logo: './seaborn-logo.png',
    name: 'Seaborn'
  },
  {
    logo: './sklearn-logo.png',
    name: 'Scikit-Learn'
  },
  {
    logo: './matplot-logo.png',
    name: 'Matplotlib'
  },
  {
    logo: './d3-logo.png',
    name: 'D3'
  },
]